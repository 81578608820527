<template>
  <div>
    <div id="iframe-wrapper">
      <b-overlay :show="!loaded" spinner-variant="primary">
        <iframe
          :src="iframe.src"
          @load="finishedLoading"
          frameborder="0"
          id="iframe"
        ></iframe>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Device",
  computed: {
    ...mapGetters("users", ["getToken", "getCredentials", "username"]),
  },
  data() {
    return {
      loaded: false,
      device: null,
      iframe: {
        src: "",
      },
    };
  },
  mounted() {
    this.device = this.$route.params.device;
    // convert token to session and view device web ui
    this.iframe.src =
      `https://remote.eospowersolutions.com?action=login&jwt=${this.getToken}&onsuccess=https://${this.device.id}.remote.eospowersolutions.com` +
      (this.device.httpPath || "/") +
      `?lang=${this.$i18n.locale}${encodeURIComponent('&')}token=${this.getToken}`;
    // use encodeURIComponent('&') (=%26) instead of '&' because it is treated as parameter for the first URL part (login) otherwise!

  },
  methods: {
    finishedLoading(/*arg*/) {
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss">
@import "@/theme.scss";

#iframe {
  width: 100%;
  height: calc(100vh - #{$navbar-height});
}
</style>